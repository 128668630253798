import React from 'react';
import { auth, firestore } from './firebaseConfig'; // Make sure you import firestore here
import { GoogleAuthProvider } from 'firebase/auth';
import { Button } from '@mui/material';

const Login = () => {
  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await auth.signInWithPopup(provider);
      const user = result.user;
      await createUserDocument(user);
    } catch (error) {
      console.error('Error signing in with Google', error);
    }
  };

  const createUserDocument = async (user) => {
    const userRef = firestore.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();

    if (!snapshot.exists) {
      const { displayName, email, photoURL } = user;
      const createdAt = new Date();

      try {
        await userRef.set({
          displayName,
          email,
          photoURL,
          createdAt,
        });
      } catch (error) {
        console.error('Error creating user document', error);
      }
    }
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={signInWithGoogle}>
        Sign in with Google
      </Button>
    </div>
  );
};

export default Login;
